<template>
    <div class="app" @click="touchDisplay">
        <!--<video width="100%" height="100%" muted :poster="require(`@/assets/img/main/${preview}`)" autoplay loop><source :src="require(`@/assets/video/${video}`)" type="video/mp4" codecs="mp4" /></video>-->
        <swiper
            class="blocks"
            :modules="[Autoplay, EffectFade]"
            :autoplay="autoplay"
            effect="fade"
            :slides-per-view="1"
            :space-between="0"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
        >
            <swiper-slide v-for="image in images" :key="image.id">
                <img :src="require(`@/assets/img/main/${image.img}`)" :alt="image.img" />
            </swiper-slide>
        </swiper>
        <t-block-empty />
        <div class="touch">Коснись, чтобы начать</div>
    </div>
</template>

<script>
import { EffectFade, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
export default {
    name: "t-main",
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        const onSwiper = (swiper) => {
            console.log(swiper);
        };
        const onSlideChange = () => {
            console.log("slide change");
        };
        return {
            onSwiper,
            onSlideChange,
            EffectFade,
            Autoplay,
        };
    },
    data() {
        return {
            images: [
                {
                    id: 1,
                    img: "mk.jpg",
                },
                {
                    id: 2,
                    img: "your_gift.png",
                },
                {
                    id: 3,
                    img: "season_menu.jpg",
                },
                {
                    id: 4,
                    img: "pause.jpg",
                },
                {
                    id: 5,
                    img: "recept.png",
                },
                {
                    id: 6,
                    img: "coffee_machine.jpg",
                },
                {
                    id: 7,
                    img: "tur_game.jpg",
                },
                {
                    id: 8,
                    img: "yandex.png",
                },
                {
                    id: 9,
                    img: "minus20.jpg",
                },
                {
                    id: 10,
                    img: "t-coins.jpg",
                },
                {
                    id: 11,
                    img: "tisbi.jpg",
                },
                {
                    id: 12,
                    img: "work1.jpg",
                },
                {
                    id: 13,
                    img: "work2.jpg",
                },
                {
                    id: 14,
                    img: "work3.jpg",
                },
            ],
            effect: "fade",
            grabCursor: true,
            loop: true,
            centeredSlides: true,
            slidesPerView: "auto",
            autoplay: {
                delay: 8000,
                disableOnInteraction: false,
            },
            now: new Date(),
        };
    },
    methods: {
        touchDisplay() {
            this.$router.push("/welcome");
        },
    },
    computed: {
        currentDate() {
            return this.now.getDate() + "." + this.now.getMonth() + 1 + "." + this.now.getFullYear();
        },
    },
    mounted() {
        let i;

        for (i = 0; i < 100; i++) {
            window.clearInterval(i);
        }

        localStorage.removeItem("orderId");
        let host = window.location.href;
        let cafe_slug = host.split("/");
        this.cafe_slug = cafe_slug[2].split(".")[0];
        if (host.indexOf("localhost") != -1) {
            this.cafe_slug = "it-park";
        }
        console.log(this.cafe_slug);
        /*if (this.cafe_slug == "olymp") {
      this.images.push({
        id: 1,
        img: "fest.jpg",
      });
    }*/
        if (this.now.getDate() >= 14) {
            this.images.shift();
        }
    },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";
.app {
    position: relative;
    .touch {
        width: 100%;
        height: 320px;
        background-color: #fff;
        box-shadow: 1px 3px 25px rgba(0, 45, 29, 0.1);
        font-weight: 700;
        font-size: 48px;
        line-height: 65.47px;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .block-empty {
        height: calc(100vh - 320px);
    }
    @media (max-width: 1080px) {
        height: 177vw;
    }
    img,
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .swiper {
        width: 100%;
        @media (max-width: $ms3) {
            height: 100vh;
        }
    }
    .footer {
        position: absolute;
        bottom: 0;
    }
}
</style>
