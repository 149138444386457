<template>
    <div class="cart-page" :class="(isPopupAuth && orderObj.items.length) || isPopupPromocode ? `blur` : false">
        <t-back-to-page />
        <div class="cart-page__body">
            <div class="order-detailed">
                <div class="order-detailed__container">
                    <div v-if="orderObj && orderObj.items.length <= 0">
                        <br />
                        <h2 class="order-detailed__title">
                            <br />{{ $t("cart.cart_empty") }}.<br /><br />{{ $t("cart.cart_empty2") }}<br /><a
                                href="/products/"
                                class="btn btn-beige to-catalog"
                                >{{ $t("cart.to_catalog") }}.</a
                            >
                        </h2>
                    </div>
                    <div class="block-rows" v-if="orderObj.items.length">
                        <div class="_container">
                            <div class="block-orders">
                                <div class="block-order" v-for="item in orderObj.items" :key="item.id">
                                    <div class="block-order__img">
                                        <img
                                            :src="
                                                item.product.image
                                                    ? item.product.image
                                                    : require(`@/assets/img/${nophoto}`)
                                            "
                                            :alt="item.product.real_name"
                                        />
                                    </div>
                                    <div class="block-order__body">
                                        <div class="block-order__text">
                                            <h4 class="block-order__title">
                                                {{ item.product.real_name }}
                                            </h4>
                                            <div class="block-col">
                                                <div class="block-order__weight">{{ item.product.weight }} г</div>
                                                <div class="block-order__price">{{ item.product.price }} ₽</div>
                                            </div>
                                        </div>
                                        <div class="block-order__buttons">
                                            <div class="counter">
                                                <button
                                                    class="counter__minus"
                                                    @click="changeOrderItem(orderObj.id, item.id, 'decrease')"
                                                >
                                                    -
                                                </button>
                                                <span class="counter__counts">{{ item.quantity }}</span>
                                                <button
                                                    class="counter__plus"
                                                    @click="changeOrderItem(orderObj.id, item.id, 'increase')"
                                                >
                                                    +
                                                </button>
                                            </div>
                                            <button
                                                class="btn btn-remove flex-center"
                                                @click="changeOrderItem(orderObj.id, item.id, 'delete')"
                                            >
                                                {{ $t("buttons.remove") }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="count-tools">
                                <div class="count-tools__container">
                                    <div class="count-tools__name">
                                        <img src="@/assets/img/svg/tools.svg" alt="tools" width="19.43" height="24" />
                                        <span>Количество приборов</span>
                                        <input type="hidden" v-model="chopsticks" />
                                    </div>
                                    <div class="counter">
                                        <button class="counter__minus" @click="decreaseCounts">-</button>
                                        <span class="counter__counts">{{ chopsticks }}</span>
                                        <button class="counter__plus" @click="increaseCounts">+</button>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- <t-total-sum :orderObj="orderObj" /> -->
            <!-- <t-footer-order /> -->
            <!--<t-slider  :recommendedProducts="recommendedProducts" />-->
            <div class="slider" :recommendedProducts="recommendedProducts" v-if="orderObj.items.length">
                <div class="slider__container _container">
                    <h2 class="slider__title">Добавить к заказу?</h2>
                    <swiper
                        class="slider__blocks"
                        :modules="modules"
                        :slides-per-view="2"
                        :space-between="28"
                        navigation
                        @swiper="onSwiper"
                        @slideChange="onSlideChange"
                    >
                        <swiper-slide class="slider__block" v-for="rd in recommendedProducts" :key="rd.id">
                            <div class="slider__img">
                                <img :src="rd.image ? rd.image : require(`@/assets/img/${nophoto}`)" alt="1" />
                            </div>
                            <div class="slider__body">
                                <div class="slider__name md">
                                    {{ rd.real_name }}
                                </div>
                                <div class="slider__name mm">
                                    {{ rd.real_name.length > 30 ? rd.real_name.substr(0, 30) + "..." : rd.real_name }}
                                </div>
                                <div class="card-dish__change" v-if="orderObj.products_ids.indexOf(rd.id) != -1">
                                    <div class="counter">
                                        <button
                                            class="counter__minus"
                                            @click="changeOrderItem(orderObj.id, getOrderItem(rd.id), 'decrease')"
                                        >
                                            -
                                        </button>
                                        <span class="counter__counts">{{ showPrice(rd.id) }} ₽</span>
                                        <button
                                            class="counter__plus"
                                            @click="changeOrderItem(orderObj.id, getOrderItem(rd.id), 'increase')"
                                        >
                                            +
                                        </button>
                                    </div>
                                </div>
                                <div class="block-flex" v-if="orderObj.products_ids.indexOf(rd.id) == -1">
                                    <div class="price">{{ rd.price }} ₽</div>
                                    <div class="btn-green add-to-cart" @click="addToOrder(orderObj.id, rd.id)">+</div>
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
        </div>
        <div class="block-side" v-if="orderObj.items.length">
            <div class="_container">
                <div class="block-side__container">
                    <div class="block-side__block">
                        <div class="t-coin">
                            <div class="t-coin__body">
                                <h4 class="t-coin__title">Копи Т-коины</h4>
                                <p class="t-coin__subtite">Обменивай на блюда за 1₽</p>
                            </div>
                            <button class="t-coin__btn" @click="isPopupAuth = true">Копить Т-коины</button>
                            <div class="t-coin__img">
                                <img src="@/assets/img/coins2.png" alt="coins" />
                            </div>
                        </div>
                    </div>
                    <div class="block-side__block">
                        <div class="stroke">
                            <div class="stroke__body" v-if="isPromocode">
                                <div class="stroke__rows">
                                    <div class="stroke__flex">
                                        <div class="stroke__text">Промокод</div>
                                        <div class="stroke__promocode">{{ promocode }}</div>
                                    </div>
                                    <div class="stroke__discount">Скидка {{ orderObj.discount_amount }} руб</div>
                                </div>
                                <button class="stroke__remove" @click="removePromocode()">Удалить</button>
                            </div>
                            <div class="stroke__body" v-else @click="applyPromo()">
                                <div class="stroke__title">Ввести промокод</div>
                                <div class="stroke__arrow">
                                    <img src="@/assets/img/svg/arrow-input.svg" alt="arrow" />
                                </div>
                            </div>
                        </div>
                        <div class="block-form">
                            <div class="form_radio_btn">
                                <input id="radio-1" type="radio" name="radio" value="На подносе" checked />
                                <label for="radio-1">На подносе</label>
                            </div>
                            <div class="form_radio_btn">
                                <input id="radio-2" type="radio" name="radio" value="С собой" />
                                <label for="radio-2">С собой</label>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="block-side__btn" @click="$router.push(`/pay`)">
                    <div class="total-sum__price">
                        <span>Оформить заказ на</span>
                        <div class="total-sum__value">
                            <div v-if="isPromocode">
                                {{ orderObj.full_price }}
                                ₽
                            </div>
                            <div v-else>
                                {{ orderObj.full_price }}
                                ₽
                            </div>
                            <div class="removeOldPrice" v-if="isPromocode">
                                {{ orderObj.products_price }}
                                ₽
                            </div>
                        </div>
                    </div>
                </button>
            </div>
        </div>
    </div>
    <div class="success" v-if="isPopupSuccess">
        <p>{{ success }}</p>
        <button class="btn btn-green" @click="isPopupSuccess = false">ОК</button>
    </div>
    <div class="keyboard-block" v-if="isPopupKeyboad" @click="cancelClose()">
        <div class="keyboard" @click.stop>
            <div class="keyboard__block">
                <div class="keyboard__container _container">
                    <div class="keyboard__body">
                        <input
                            type="text"
                            v-model="message"
                            :placeholder="$t('cart.input_promo')"
                            class="keyboard__title"
                        />
                        <div class="keyboard__keys" @click="typeLetter">
                            <button class="keyboard__key key" v-for="(number, index) in 9" :key="index">
                                {{ number }}
                            </button>
                            <button class="keyboard__key key">0</button>
                            <button class="btn btn-green key">
                                <img src="@/assets/img/svg/clear.svg" alt="clear" class="clear" />
                            </button>
                            <button class="btn btn-green btn-long key">
                                {{ $t("cart.btn_clear") }}
                            </button>
                            <button class="keyboard__key key" v-for="letter in letters" :key="letter.id">
                                {{ letter.char }}
                            </button>
                            <button class="btn btn-green key">
                                <img src="@/assets/img/svg/space.svg" alt="space" class="space" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="keyboard__buttons">
                <button class="btn btn-red" @click="cancelClose()">
                    {{ $t("cart.cancel") }}
                </button>
                <button class="btnApply" @click="addDiscount()">
                    {{ $t("cart.btn_apply") }}
                </button>
            </div>
        </div>
    </div>
    <PopupAuth
        v-if="isPopupAuth && orderObj.items.length"
        @click="closeAuth"
        @close-auth="closeAuth"
        :orderObj="orderObj"
    />
    <PopupPromocode
        v-if="isPopupPromocode"
        @is-popup-keyboad="isPopupPromocode = false"
        @apply-promocode="applyPromocode"
        :promocode="promocode"
    />
</template>

<script>
import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import axios from "axios";
import { api } from "@/api/api";
import PopupAuth from "@/components/PopupAuth.vue";
import PopupPromocode from "@/components/PopupPromocode.vue";

export default {
    name: "t-order-detailed",
    components: {
        Swiper,
        SwiperSlide,
        PopupAuth,
        PopupPromocode,
    },
    setup() {
        const onSwiper = (swiper) => {
            console.log(swiper);
        };
        const onSlideChange = () => {
            console.log("slide change");
        };
        return {
            onSwiper,
            onSlideChange,
            modules: [Navigation, Pagination, A11y],
        };
    },
    data() {
        return {
            isPopupPromocode: false,
            isPromocode: false,
            isPopupAuth: true,
            orderId: localStorage.getItem("orderId"),
            orderObj: { items: [] },
            recommendedProducts: [],
            isPopupKeyboad: false,
            isPopupPhone: false,
            deduct_points: `Накопить | Списать баллы`,
            discount_error: "",
            message: "",
            success: `Неверный промокод! Попробуйте ещё раз!`,
            isPopupSuccess: false,
            letters: [
                { id: 1, char: `й` },
                { id: 2, char: `ц` },
                { id: 3, char: `у` },
                { id: 4, char: `к` },
                { id: 5, char: `е` },
                { id: 6, char: `н` },
                { id: 7, char: `г` },
                { id: 8, char: `ш` },
                { id: 9, char: `щ` },
                { id: 10, char: `з` },
                { id: 11, char: `х` },
                { id: 12, char: `ъ` },
                { id: 13, char: `ё` },
                { id: 14, char: `ф` },
                { id: 15, char: `ы` },
                { id: 16, char: `в` },
                { id: 17, char: `а` },
                { id: 18, char: `п` },
                { id: 19, char: `р` },
                { id: 20, char: `о` },
                { id: 21, char: `л` },
                { id: 22, char: `д` },
                { id: 23, char: `ж` },
                { id: 24, char: `э` },
                { id: 25, char: `я` },
                { id: 26, char: `ч` },
                { id: 27, char: `с` },
                { id: 28, char: `м` },
                { id: 29, char: `и` },
                { id: 31, char: `т` },
                { id: 32, char: `ь` },
                { id: 33, char: `б` },
                { id: 34, char: `ю` },
                { id: 35, char: `_` },
            ],
            discount_img: `discount.png`,
            title: `Хотите получить блюда\n с 50% скидкой?`,
            promocode: "777",
            nophoto: "nophoto.jpg",
            //chopsticks: 1,
        };
    },
    methods: {
        // decreaseCounts() {
        //     this.chopsticks--;
        //     if (this.chopsticks < 1) this.chopsticks = 1;
        // },
        // increaseCounts() {
        //     this.chopsticks++;
        // },
        applyPromo() {
            this.isPopupPromocode = true;
        },
        applyPromocode(data) {
            let self = this;
            if (data) {
                axios
                    .post(api + `discount-add/`, {
                        order_id: self.orderObj.id,
                        discount_slug: data,
                    })
                    .then(function (response) {
                        self.orderObj = response.data;
                        self.isPopupPromocode = false;
                        self.promocode = data;
                        self.isPromocode = true;
                        self.success = `Промокод применён!`;
                    })
                    .catch(function () {
                        self.message = "";
                        self.isPopupPromocode = false;
                        self.success = `Неверный промокод! Попробуйте ещё раз!`;
                    });
            }
        },
        removePromocode() {
            let self = this;

            axios
                .post(api + `promocodes/cancel/`, {
                    order_id: self.orderObj.id,
                })
                .then(function (response) {
                    self.orderObj = response.data.order_obj;
                    self.isPromocode = false;
                });
        },
        closeAuth() {
            this.isPopupAuth = false;
        },
        cancelClose() {
            this.isPopupKeyboad = false;
            this.message = "";
        },
        showPrice: function (product_id) {
            let output = 0;
            this.orderObj.items.forEach(function (item) {
                if (item.product.id == product_id) {
                    console.log(item.price, item.quantity, item.price * item.quantity);
                    output = item.product.price * item.quantity;
                }
            });
            return output;
        },
        getOrderItem: function (product_id) {
            let output = 0;
            this.orderObj.items.forEach(function (item) {
                if (item.product.id == product_id) {
                    output = item.id;
                }
            });
            return output;
        },
        addToOrder(order_id, product_id) {
            let self = this;
            axios
                .post(api + `order-add/`, {
                    order_id: order_id,
                    product_id: product_id,
                })
                .then(function (response) {
                    self.orderObj = response.data;
                });
        },
        changeOrderItem(order_id, order_item_id, type) {
            let self = this;
            axios
                .post(api + `order-change/`, {
                    order_id: order_id,
                    order_item_id: order_item_id,
                    type: type,
                })
                .then(function (response) {
                    self.orderObj = response.data;
                });
        },
        addDiscount() {
            let self = this;
            self.isPopupSuccess = true;

            axios
                .post(api + `discount-add/`, {
                    order_id: self.orderObj.id,
                    discount_slug: this.message,
                })
                .then(function (response) {
                    self.orderObj = response.data;
                    self.isPopupKeyboad = false;
                    self.success = `Промокод применён!`;
                })
                .catch(function (response) {
                    self.message = "";
                    self.isPopupKeyboad = false;
                    self.discount_error = response.data.error;
                    self.success = `Неверный промокод! Попробуйте ещё раз!`;
                });
        },
        typeLetter() {
            if (event.target.className !== "phone-rows" && event.target.className !== "btnApply") {
                let key = event.target.innerHTML || event.target.className;
                if (key === "clear") this.message = this.message.substr(0, this.message.length - 1);
                else if (key === "Очистить") this.message = "";
                else if (key === "space") this.message += " ";
                else this.message += key.toUpperCase();
            }
        },
        /*changeOrderItem(order_id, order_fast_set_id, type, express) {
      let self = this;
      axios
        .post(api + `order-fast-set-change/`, {
          order_id: order_id,
          order_fast_set_id: self.orderObj.order_fast_sets[0].id,
          type: type,
          express: express ? express : false,
        })
        .then(function (response) {
          self.orderObj = response.data;
        });
    },*/
    },
    created() {
        if (!this.orderId) {
            this.$router.push("/");
        }
        let self = this;
        axios.get(api + `orders/` + this.orderId + "/").then(function (response) {
            self.orderObj = response.data;
        });
        axios.get(api + `recommended-in-cart/?order_id=` + this.orderId).then(function (response) {
            self.recommendedProducts = response.data;
        });
        this.discount_error = "";
    },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";
@import "~@/assets/scss/keyboards";
@import "~@/assets/scss/auth";
.cart-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding-bottom: 30px;
    &__body {
        flex: 1 1 auto;
    }
}
.block-rows {
    display: flex;
    flex-direction: column;
    background-color: #f8f9f8;
    padding-bottom: 30px;
    ._container {
        width: 100%;
    }
}

.block-order__title p {
    width: 100%;
}

.block-apply {
    display: flex;
    padding: 0 38px;
    margin: 0 -1rem;

    .apply-promo {
        width: 100%;
        height: 70px;
        margin: 2rem 0.5rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: $ms3) {
            height: 50px;
        }
        @media (max-width: $ms4) {
            margin-top: 1rem;
        }
    }
}

.to-catalog {
    width: fit-content;
    margin: 1rem auto;
    padding: 10px 2rem;
}

.block-orders {
    padding: 50px 0 30px;
    overflow-x: hidden;
    overflow-y: auto;
    height: 50vh;
    &::-webkit-scrollbar-track {
        background: rgba(40, 115, 95, 0.2);
        border-radius: 10px;
        margin-top: 40px;
        @media (max-width: $ms3) {
            margin-top: 20px;
        }
    }
    &::-webkit-scrollbar {
        width: 9px;
    }
    &::-webkit-scrollbar-thumb {
        background: #28735f;
        border-radius: 10px;
    }

    @media (max-width: $ms3) {
        padding: 0 15px;
    }
    @media (max-width: $ms4) {
        padding: 0 10px;
    }
}

.order-detailed {
    position: relative;
    background: none;
    &__promocode {
        @media (max-width: $ms3) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &__title {
        text-align: center;
        color: #000;
        @include adaptive-font(50, 18, 0);
        @include adaptive-value("line-height", 51, 20, 0);
    }

    &__promocode {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        @media (max-width: $ms3) {
            margin: 20px auto 0;
        }

        .btn {
            flex: 1 1 50%;
            height: 70px;
            display: flex;
            justify-content: center;
            margin: 45px 15px 0;
            @media (max-width: $ms3) {
                width: 100%;
                height: 50px;
                margin: 20px 15px 0;
            }
        }
    }
}
</style>
<style lang="scss" scoped>
@media (max-width: 767px) {
    .cart-page {
        overflow-x: hidden;
    }
    .block-order {
        flex-direction: column;
        height: auto;
    }
    .block-order__img {
        min-width: unset;
        max-width: unset;
    }
    .block-order__body {
        padding: 15px;
        flex-direction: column;
    }
    .block-col {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;
        .block-order__weight {
            margin-top: 0;
        }
        .block-order__price {
            display: flex;
            justify-content: flex-end;
        }
    }
    .block-order__buttons {
        min-width: 100%;
        max-width: 100%;
        .counter,
        .btn {
            margin-top: 1rem;
        }
    }
    .count-tools {
        padding: 15px;
        .counter {
            width: 100%;
            margin-top: 1rem;
        }
    }
    .count-tools__name {
        font-size: 14px;
        line-height: 20px;
    }
    .count-tools__container {
        flex-direction: column;
    }
    .slider__title {
        font-size: 18px;
        line-height: 20px;
    }
    .slider__body {
        padding: 10px;
    }
    .block-side__container {
        flex-direction: column;
    }
    .block-side__block:not(:first-child) {
        margin-left: 0;
    }
    .block-side .t-coin__img {
        display: none;
    }
    .block-side .t-coin {
        padding: 15px;
    }
    .block-side .t-coin__btn {
        width: 100%;
        height: 50px;
    }
    .block-side .stroke,
    .block-form {
        margin-top: 1rem;
    }
    .total-sum__price {
        flex-direction: column;
    }
    .total-sum__price,
    .total-sum__discount {
        font-size: 20px;
        line-height: 30px;
    }
    .slider__img {
        max-width: 100px;
    }
    .slider__block {
        width: 100% !important;
        min-height: 100px;
        max-height: 100px;
    }
    .slider__name {
        font-size: 14px;
        line-height: 20px;
    }
    .slider {
        .counter {
            width: 100%;
            height: 40px;
            font-size: 14px;
            line-height: 20px;
        }
    }
    .counter__minus,
    .counter__plus {
        width: 40px;
        height: 40px;
    }
    .slider .add-to-cart {
        width: 40px;
        height: 40px;
    }
    .card-dish__change {
        margin: 5px 0px 0px 0px;
    }
    .popupKeyboad .keyboard__input-promo input,
    .popupKeyboad .keyboard__input-promo button {
        font-size: 18px;
        line-height: 20px;
    }
    .popupKeyboad .keyboard__input-promo {
        height: auto;
        padding: 0;
        margin-top: 10px;
        margin-bottom: 10px;
        flex-direction: column;
    }
    .keyboard__input-block {
        height: 50px;
        padding: 0 1rem;
    }
}
</style>
